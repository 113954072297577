/* Coded with love by Mutiullah Samim */

.user_card {
  height: 450px;
  width: 120%;
  margin-top: -47px;
  margin-bottom: 45px;
  background: #f39c12;
  position: relative;
  display: flex;
  justify-content: top;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -85px;
  border-radius: 50%;
  background: #000000;
  padding: 10px;
  text-align: center;
}
.brand_logo {
  padding-top: 0px;
  padding-bottom: 2px;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #f39c12;
}
.form_container {
  margin-top: 80px;
}
.login_btn,
.login_btn:focus,
.login_btn:active {
  background: #222222 !important;
  color: #f39c12 !important;
  width: 100%;
}
.login_btn:hover {
  background: #222222 !important;
  color: yellow !important;
  width: 100%;
}
.login_container {
  padding: 0 0rem;
}
.input-group-text {
  background: #222222 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_email,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.mainModal {
  backdrop-filter: blur(3px) grayscale(100%);
  padding-top: 5em;
}
.mainModal .modal-header {
  border: 0px;
}

.mainModal .modal-header {
  border: 0px;
}
.mainModal .modal-header .close {
  padding-right: 2rem;
  z-index: 1;
}
.mainModal .modal-content {
  background: transparent !important;
  border: 0px;
}

.iModal {
  overflow-y: inherit;
  backdrop-filter: blur(3px) grayscale(100%);
  padding: 1em;
  border: 0px;
}
.modal-open {
  overflow-y: auto;
}
.modal {
  overflow-y: auto;
}
.modal-dialog {
  border: 0px !important;
}

.iModal .modal-content {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  background: #f39c12;

  color: #222222;
}

.iModal .modal-header {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 0px;

  background-color: #222222;
  color: rgba(255, 255, 0, 0.89);
}
.iModal .modal-body {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 25px;
  padding-bottom: 25px;

  color: #222222;
}

.users {
  width: 100%;
  margin-top: -47px;
  margin-bottom: 45px;
  padding: 1em;
  padding-top: 100px;
  background: #f39c12;
  position: relative;
  display: flex;
  justify-content: top;
  text-align: center;
  flex-direction: column;

  color: #222222;
  background-color: #f39c12;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.userName {
  color: #222222;
  font-size: x-large;
  padding-bottom: 1em;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.userSubscriptions {
  padding-bottom: 1em;
}
.userSubscription {
  padding-top: 1.4em;
}
.userSubscriptionHeader {
  padding-bottom: 0.4em;
  font-size: large;
}
.userSubscriptionBody {
  padding-bottom: 0.4em;
  font-size: small;
}
.userSubscriptionFooter {
  font-size: small;
}

.userPayment {
  padding-bottom: 1em;
  font-size: medium;
}

.userPaymentInfo {
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: medium;
}

.usersFooter {
  color: #222222;
  font-size: small;

  border-top-style: solid;
  border-top-width: 1px;
  line-height: 1.3em;
  padding-top: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.users a:link {
  color: blue;
  background-color: transparent;
  text-decoration: none;
}

.users a:visited {
  color: blue;
  background-color: transparent;
  text-decoration: none;
}

.users a:hover {
  color: blue;
  background-color: transparent;
  text-decoration: underline;
}

.users a:active {
  color: blue;
  background-color: transparent;
  text-decoration: underline;
}

.line {
  width: 6%;
  position: absolute;
  left: 47%;

  border-bottom-style: solid;
  border-bottom-width: 0px;
}

.line:after {
  content: "";
  position: absolute;
  left: 65%;
  height: 5px;
  width: 5px;
  border: 3px solid;
  margin-top: -2px;
  margin-left: -7px;
  /* transform: rotate(45deg); */
}

.modalButton,
.modalButton:focus,
.modalButton:active {
  color: #f39c12 !important;
  background-color: #222222 !important;
  border-color: #222222 !important; /*set the color you want here*/
}

.modalButton.clicked {
  color: #f39c12 !important;
  background-color: #f39c12 !important;
  border-color: #222222 !important; /*set the color you want here*/
}

.modalButton:hover {
  color: #ffff00 !important;
  background-color: #222222 !important;
  border-color: #222222 !important; /*set the color you want here*/
}


h1 {
  font-size: min(30px, 5vw);
}

body{
  font-size: min(15px,3.2vw) !important;
}
.modal-body{
  padding: min(15px,2vw);
}
.modal-content
{
  background-color: #fffbf5;
  padding-top: 10px !important;
  
}
p.item-intro
{
  font-size: min(15px,4vw) !important;
}
.modal-header {
  border: none;
}

.close {
  padding-right: min(40px, 7vw) !important;
}
.btn {
  font-size:  min(15px,4vw) ;
}